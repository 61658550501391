<template>
    <div class="card card-custom">
        <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
      <div class="row justify-content-center">
          <div class="col-xl-12">
                <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">
                    <v-icon>mdi-application</v-icon> Update Order
                </h3>
                <createOrder></createOrder>
               </div>
          </div>
       </div>
      </div>
      <div class="card-body p-0">
      </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import createOrder from "@/components/OrderCreate/createSalesOrder.vue";

export default {
    name: 'orders',
    data () {
      return {
        radio1: 'New York',
        tab: null,
        text:"welcome"
      };
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Dashboard", route: "/dashboard"},
            {title: "Order Management", route: "/order/list"},
            {title: "Update Order"},
    ]);
    },
    components:{
        createOrder,        
    }
}
</script>